import styled from "styled-components";
import { colors } from "../../colors";

export const Header1Styled = styled.header`
  width: 560px;
  font-family: "Open Sans";
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  line-height: 54px;
  color: ${colors.catalinaBlue};
`;

export const HeaderOnboarding1Styled = styled(Header1Styled)`
  width: 600px;
  padding-top: 10px;
`;

export const H1 = styled.h1`
  font-size: 24px;
  font-weight: 700;
  font-family: "Open Sans";
  color: ${colors.catalinaBlue};
`;

export const BlueH3 = styled.h3`
  font-size: 21px;
  font-weight: 700;
  font-family: "Open Sans";
  color: ${colors.catalinaBlue};
`;

export const Header2Styled = styled.header`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 28px;
  color: ${colors.pastelDarkBlue};
  display: inline-flex;

  & > svg {
    margin-top: 2px;

    &.far-left {
      margin-right: 16px;
    }

    &.far-right {
      margin-left: 16px;
    }
  }
`;

export const $ModalHeader = styled.header`
  font-family: "Open Sans";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  line-height: 39px;
  color: ${colors.darkBlue};
`;

export const StepThreeHeader = styled.h1`
  align-items: left;
  display: flex;
  flex-grow: 1;
  margin-top: 8px;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: bold;
  color: ${colors.catalinaBlue};
`;

export const HeaderH2Styled = styled.h2`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  color: ${colors.catalinaBlue};
`;

export const StepThreeHeaderTwo = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-left: 20px;
  margin-bottom: 0px;
  color: ${colors.blueRey};
`;

export const FVFHeader = styled.header`
  width: 434px;
  height: 29px;
  object-fit: contain;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #053368;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.catalinaBlue};
`;

export const BizListings = styled.header`
  font-family: Oxygen;
  font-size: 16px;
  line-height: 24px;
  padding-top: 40px;
  font-style: normal;
  font-weight: normal;
  color: ${colors.blueRey};
`;

export const BizListingTitle = styled.header`
  padding-top: 20px;
  padding-left: 170px;
  padding-bottom: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: ${colors.catalinaBlue};
`;

export const BizListingBoxTitle = styled.div`
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 16px;
  padding-top: 16px;
  color: ${colors.catalinaBlue};
`;

export const BizListingHeading = styled.header`
  font-family: Open Sans;
  font-size: 24px;
  line-height: 36px;
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  width: 80%;
  color: ${colors.catalinaBlue};
`;

export const ServerErrors = styled.header`
  width: 302px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: ${colors.catalinaBlue};
  padding-left: 20px;
  padding-top: 24px;
`;

export const HeaderRight = styled.header`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  float: right;
  position: fixed;
  right: 24px;
  color: #939393;
`;
export const HeaderRightTop = styled(HeaderRight)`
  top: 16px;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 640px) and (max-width: 990px) {
    z-index: 1;
  }
`;
export const HeaderRightBottom = styled(HeaderRight)`
  z-index: -1;
  bottom: 16px;
  font-size: 10px;
  line-height: 15px;
  @media (max-width: 990px) {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: -1;
    bottom: 10px;
    float: center;
    right: 0;
  }
`;

export const H1Reporting = styled(Header1Styled)`
  display: inline-block;
  font-size: 24px;
  line-height: 36px;
  vertical-align: top;
`;
