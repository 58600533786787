import styled from "styled-components";
import { colors } from "../../colors";
import { SimpleLink } from "./Links";
import closeButton from "@views/builder/sites/assets/CloseButton.svg";
interface ButtonProps {
  width?: string;
  fontSize?: string;
  fontWeight?: string | number;
  height?: string | number;
  lineHeight?: string | number;
}

interface PillLinkProps {
  width?: string;
  disabled?: boolean;
  fontSize?: string;
  fontWeight?: string | number;
  paddLeft?: string;
  backgroundColor?: string;
}

export const CloseButton = styled.div`
  background-color: ${colors.gray};
  background-image: url(${closeButton});
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 10px;
  padding: 10px;
  border-radius: 30px;
  &:hover {
    text-decoration: none;
    background-color: ${colors.pastelDarkBlue};
  }
`;

export const BasePillButton = styled.button<ButtonProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  background-color: ${(props) =>
    props.disabled ? colors.lightGray : colors.pastelDarkBlue};
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  line-height: ${(props) => props.lineHeight || "16px"};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  font-style: normal;
  height: ${(props) => props.height || "40px"};

  & > svg {
    &.far-left {
      margin-right: 16px;
    }

    &.far-right {
      margin-left: 16px;
    }

    & > path {
      fill: ${colors.white};
    }
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled ? colors.lightGray : colors.blueButtonHover};
    box-shadow: ${(props) =>
      props.disabled ? "0px" : "0px 8px 24px rgba(48, 49, 51, 0.1)"};
  }

  &:active {
    background-color: ${(props) =>
      props.disabled ? colors.lightGray : colors.steelBlue};
    box-shadow: 0px;
  }
`;

export const PillButton = styled(BasePillButton)`
  width: auto;
  padding: 8px 16px;
`;

export const PillButtonModal = styled(BasePillButton)`
  width: 100px;
  height: 35px;
  font-weight: 500;
`;

export const DeleteClose = styled(PillButton)`
  margin-right: 24px;
`;

export const IntegrationPayWallAccept = styled(PillButton)`
  width: 330px;
  text-align: center;
  margin-left: 8px;
  margin-top: 24px;
`;

export const ModalClose = styled.button`
  background-color: ${colors.blueRey};
  border: 0;
  color: ${colors.white};
  padding: 0;
  margin-top: 16px;
  margin-right: 16px;
`;

export const CsvExport = styled.div`
  background-color: ${colors.blueRey};
  border: 0;
  border-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  width: 130px;
  margin-left: 20px;
  padding-left: 18px;
  padding-top: 10px;
  display: inline-block;
  a {
    color: ${colors.white};
    width: 100px;
  }
`;

export const CsvReset = styled.div`
  background-color: ${colors.blueRey};
  border: 0;
  border-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  width: 130px;
  margin-left: 220px;
  padding-left: 18px;
  padding-top: 10px;
  display: inline-block;
  a {
    color: ${colors.white};
    width: 100px;
  }
`;

export const ButtonGrouping = styled.div`
  padding-top: 7px;
  min-width: 600px;
`;

export const FVFButton = styled.button`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.white};
  width: 436px;
  height: 44px;
  border-radius: 24px;
  margin-top: 8px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 10px;
  margin-top: 20px;
`;

export const DangerPillButton = styled(PillButton)`
  background-color: ${colors.redDanger};
`;

export const PillLink = styled.a<PillLinkProps>`
  text-decoration: none;
  align-items: center;
  background-color: ${(props) =>
    props.disabled
      ? colors.lightGray
      : props.backgroundColor
      ? props.backgroundColor
      : colors.pastelDarkBlue};
  border: 0;
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  width: ${(props) => props.width || "180px"};
  display: flex;
  font-family: "Open Sans";
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-style: normal;
  height: 40px;
  line-height: ${(props) => props.fontSize || "16px"};
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  & > svg {
    &.far-left {
      margin-right: 16px;
    }

    &.far-right {
      margin-left: 16px;
    }

    & > path {
      fill: ${colors.white};
    }
  }

  &:hover {
    text-decoration: none;
    color: ${colors.white};
    background-color: ${(props) =>
      props.disabled ? colors.lightGray : colors.blueButtonHover};
    box-shadow: ${(props) =>
      props.disabled ? "0px" : "0px 8px 24px rgba(48, 49, 51, 0.1)"};
  }

  :focus {
    text-decoration: none;
    color: ${colors.white};
    background-color: ${colors.blueRey};
  }

  &:active {
    text-decoration: none;
    color: ${colors.white};
    background-color: ${(props) =>
      props.disabled ? colors.lightGray : colors.steelBlue};
    box-shadow: 0px;
  }
`;

export const ButtonGroupingListing = styled.div`
  padding-top: 40px;
  padding-left: 285px;
`;

export const ListingLinkLeft = styled(SimpleLink)`
  margin-right: 178px;
`;

export const ListingLinkRight = styled(SimpleLink)`
  margin-top: 40px;
`;

interface WideButtonProps {
  img?: string;
  imgHover?: string;
}
export const WideBlueButton = styled(BasePillButton)<WideButtonProps>`
  width: auto;
  padding: 8px 16px;
  margin: 24px 0px;
  background-color: ${colors.white};
  color: ${colors.pastelDarkBlue};
  border: 2px solid ${colors.pastelDarkBlue};
  background-image: ${(props) => (props.img ? `url(${props.img})` : "none")};
  background-repeat: no-repeat;
  background-position: 180px center;
  &:focus {
    text-decoration: none;
    background-image: url(${(props) => props.imgHover});
    background-color: ${(props) =>
      props.disabled ? colors.lightGray : colors.sanJuanBlue};
    color: ${colors.white};
  }
  &:hover {
    background-image: url(${(props) => props.imgHover});
    background-color: ${(props) =>
      props.disabled ? colors.lightGray : colors.sanJuanBlue};
    color: ${colors.white};
    box-shadow: ${(props) =>
      props.disabled ? "0px" : "0px 8px 24px rgba(48, 49, 51, 0.1)"};
  }
  &:active {
    background-image: url(${(props) => props.imgHover});
    background-color: ${(props) =>
      props.disabled ? colors.lightGray : colors.sanJuanBlue};
    color: ${colors.white};
    box-shadow: 0px;
  }
`;
