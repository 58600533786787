import axios from "axios";
import React, { useState, useRef, useCallback } from "react";
import { randomBytes } from "crypto";
import { parse } from "node-html-parser";

export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function getCSRFToken(): string {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return meta ? meta.getAttribute("content") : null;
}

export async function pollJob(jobId) {
  const response = await axios.get("/poll_job", {
    params: {
      job_id: jobId,
    },
    withCredentials: true,
  });
  const jobStatus = response.data.status;

  if (jobStatus.match(/complete|retry/)) {
    return true;
  } else {
    await wait(1000);
    return pollJob(jobId);
  }
}

export function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const useHover = <T extends HTMLElement>(): [
  (node?: T | null) => void,
  boolean
] => {
  const [value, setValue] = useState(false);

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseOver = useCallback(() => setValue(true), []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  const ref = useRef<T>();
  const callbackRef = useCallback<(node?: null | T) => void>(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener("mouseover", handleMouseOver);
        ref.current.removeEventListener("mouseout", handleMouseOut);
      }

      ref.current = node || undefined;

      if (ref.current) {
        ref.current.addEventListener("mouseover", handleMouseOver);
        ref.current.addEventListener("mouseout", handleMouseOut);
      }
    },
    [handleMouseOver, handleMouseOut]
  );

  return [callbackRef, value];
};

export const getCookies = () => {
  const rawCookies = document.cookie.split(";");
  let cookies = {};
  for (let i = 0; i < rawCookies.length; i++) {
    let cur = rawCookies[i].split("=");
    cookies[cur[0].trim()] = cur[1];
  }
  return cookies;
};

export const topMenuHeight = () => {
  const elementHeights = {
    "#menu-application": 50,
    ".prominent-notification": 30,
    ".impersonating-user-banner": 30,
    "#funnel-v3-header": 66,
  }
  const heights = Object.entries(elementHeights).map(([selector, height]) => {
    return document.querySelector(selector) === null ? 0 : height;
  });
  return heights.reduce(
    (accumulator, currentValue) => accumulator + currentValue
  );
};

export const isZero = (number) => {
  return Math.sign(number) === 0 || isNaN(Math.sign(number));
};

export const generateQuillToolbarId = () => {
  const toolbarId = `quill-toolbar-generated-${randomBytes(16).toString(
    "hex"
  )}`;

  return [toolbarId, `#${toolbarId}`];
};

export const extractTextFromHtml = (html: string) => {
  if (!html) return ""; // return empty when there are no html to parse
  return parse(html).text;
};
