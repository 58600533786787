import styled from "styled-components";
import { colors } from "../../colors";
import React from "react";
import ReactTooltip from "react-tooltip";

interface ILinkProps {
  readonly inactiveColor?: string;
  readonly inactiveUnderline?: boolean;
  readonly hoverColor?: string;
  readonly hoverUnderline?: boolean;
  readonly activeColor?: string;
  readonly activeUnderline?: boolean;
  readonly hoverBackground?: string;
  readonly disabled?: boolean;
}

export const BoldBlueLink = styled.a`
  letter-spacing: 0.5px;
  text-decoration: none;
  font-weight: 700;
  color: ${colors.pastelDarkBlue};
  font-size: "14px";
  line-height: "20px";
  text-decoration: none;
  font-style: bold;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const BoldGreyLink = styled.a`
  font-family: Open Sans;
  font-style: normal;

  letter-spacing: 0.5px;
  color: ${colors.gray};
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const GrayLinkTheme: ILinkProps = {
  inactiveColor: colors.gray,
  inactiveUnderline: false,
  hoverUnderline: false,
  activeUnderline: false,
};

export const SimpleLink = styled.a<ILinkProps>`
  color: ${(props) =>
    props.inactiveColor ? props.inactiveColor : colors.blueLinkInactive};
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  font-weight: normal;
  font-size: 18px;
  text-decoration: ${(props) =>
    props.inactiveUnderline ? "underline" : "none"};

  &:hover {
    color: ${(props) =>
      props.hoverColor ? props.hoverColor : colors.blueButtonHover};
    text-decoration: ${(props) =>
      props.hoverUnderline === false ? "none" : "underline"};
    background-color: ${(props) =>
      props.hoverBackground ? props.hoverBackground : "none"};
  }

  &:active {
    color: ${(props) =>
      props.activeColor ? props.activeColor : colors.catalinaBlue};
    text-decoration: ${(props) =>
      props.activeUnderline === false ? "none" : "underline"};
  }
`;
export const DownloadLink = styled(SimpleLink)`
  padding: 5px;
  font-size: 14px;
  border-radius: 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) =>
    props.disabled ? colors.blueLinkBackgroundHover : ""};
  color: ${colors.pastelDarkBlue};
`;

const BackLinkModal = styled(SimpleLink)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const BackLinkModalIntPayWall = styled(BackLinkModal)`
  margin-top: 24px;
  color: gray;
`;

const BackLink = styled(SimpleLink)``;
BackLink.defaultProps = GrayLinkTheme;

const ListingLink = styled.a`
  padding-left: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.blueLinkInactive};
`;

const StyledTooltip = styled(ReactTooltip)`
  border-radius: 7px;
  position: fixed;
  opacity: 1 !important;
`;

export const Tooltip = ({ ...props }) => <StyledTooltip {...props} />;

export { BackLink, ListingLink, BackLinkModal };
