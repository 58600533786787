import React from "react";
import { $H1, $H2, $H3, $H4 } from "./Header.sc";

export interface IH {
  size?: "1" | "2" | "3" | "4";
  color?: string;
  fontWeight?: "bold" | "light" | "normal";
  margin?: string;
  children: React.ReactNode;
}

const H: React.FC<IH> = ({
  margin = "0",
  size = "2",
  color = "primary1",
  fontWeight = "bold",
  children,
}) => {
  switch (size) {
    case "1":
      return (
        <$H1 color={color} fontWeight={fontWeight} margin={margin}>
          {children}
        </$H1>
      );
    case "2":
      return (
        <$H2 color={color} fontWeight={fontWeight} margin={margin} as="h2">
          {children}
        </$H2>
      );
    case "3":
      return (
        <$H3 color={color} fontWeight={fontWeight} margin={margin} as="h3">
          {children}
        </$H3>
      );
    default:
      return (
        <$H4 color={color} fontWeight={fontWeight} margin={margin} as="h4">
          {children}
        </$H4>
      );
  }
};

export default H;
