import styled from "styled-components";
import { colors } from "../../colors";

import chevronUp from "../../../assets/icons/chevrons/chevron-up.svg";
import chevronDown from "../../../assets/icons/chevrons/chevron-down.svg";

interface ModalBodyContainer {
  topPadding?: string;
  leftPadding?: string;
  rightPadding?: string;
}

export const FVFModalBodyContainer = styled.div<ModalBodyContainer>`
  padding-top: ${(props) => (props.topPadding ? props.topPadding : "32px")};
  padding-left: ${(props) => (props.leftPadding ? props.leftPadding : "32px")};
  padding-right: ${(props) =>
    props.rightPadding ? props.rightPadding : "32px"};
`;

export const TwelvePxSpacer = styled.span`
  margin-right: 6px;
  margin-left: 6px;
`;

export const GrayBodyCopy = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  color: ${colors.gray};
`;

export const GraySubBodyCopy = styled.p`
  font-family: "Open Sans";
  font-size: 14px;
  color: ${colors.gray};
`;
export const $SmallBodyCopy = styled.small`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.gray};
`;

interface CopyProps {
  fS?: string;
  color?: string;
  lH?: string;
}

export const BodyCopy = styled.p<CopyProps>`
  font-family: "Open Sans";
  font-style: normal;
  font-size: ${(props) => (props.fS ? props.fS : "18px")};
  font-weight: 400;
  line-height: ${(props) => (props.lH ? props.lH : "32px")};
  color: ${(props) => (props.color ? props.color : colors.gray)};
`;

export const BlackBodyCopy = styled.p`
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
`;

export const BlackTitleHeader2 = styled.h2`
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
  font-size: 21px;
  line-height: 32px;
`;

export const RedBodyCopy = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.redDanger};
`;

export const ParagraphBNF = styled.p`
  font-family: Open Sans;
  line-height: 24px;
  height: 60px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  margin-top: 16px;
  margin-bottom: 0px;
  color: ${colors.gray};
`;

export const MapTitle = styled.div`
  width: 292px;
  height: 40px;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #221666;
  padding-left: 20px;
`;

export const IntegrationTitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-left: 240px;
  color: ${colors.catalinaBlue};
`;

export const IntegrationPayTitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-left: 24px;
  color: ${colors.catalinaBlue};
`;

export const IntegrationPayBody = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray};
  padding: 24px;
  text-align: left;
`;

export const IntegrationPriceText = styled(IntegrationPayBody)`
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 60px;
  color: #053368;
`;

export const SnackBarOuter = styled.div`
  font-family: Open Sans;
  height: 42px;
  width: 647px;
  padding-left: 16px;
  padding-top: 11px;
  color: ${colors.white};
  background-color: ${colors.primary2};
  box-shadow: 0px 2px 4px rgba(48, 49, 51, 0.1),
    0px 0px 1px rgba(48, 49, 51, 0.05);
  border-radius: 4px;
  a {
    color: ${colors.white};
    text-decoration-line: underline;
  }
  a:hover {
    color: ${colors.catalinaBlue};
    text-decoration-line: none;
  }
`;

export const SnackBarGroup = styled.div`
  bottom: 0;
  left: 24px;
  position: absolute;
`;

export const IntegrationLink = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.primary2};
`;

export const GoogleMapLists = styled.ol`
  width: 700px;
  object-fit: contain;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  margin-top: 30px;
  color: ${colors.gray};
  padding-inline-start: 15px;
`;

export const GoogleMapsLists = styled.ul`
  list-style: none;
  object-fit: contain;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
  padding: 0;
`;

export const GoogleMapsLi = styled.li`
  list-style: none;
  object-fit: contain;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  margin: 0;
  padding: 0;
`;

export const ServiceAreaBizListContainer = styled.ol`
  padding-inline-start: 0px;
  margin-bottom: 0px;
  margin-left: 20px;
  margin-top: 16pm;
`;

export const ServiceAreaBizLists = styled.li`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray};
`;

export const ErrorFields = styled.div`
  color: ${colors.validationRed} !important;
`;

export const MapStyled = styled.div`
  border-radius: 5%;
  box-shadow: 2px 5px 4px 5px #888888;
  margin-left: 20px;
  height: 350px;
  width: 550px;
`;

interface BizListingContainer {
  backgroundColor?: string;
}

export const BizListingBox = styled.div<BizListingContainer>`
  width: 600px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 16px;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : colors.listingCardBackgroundDefault};
  border: 2px solid #e6ecf1;
  border-radius: 10px;
  display: block;
`;

export const GoogleListingCardAddendum = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  display: inline;
  align-items: center;
  color: ${colors.gray};
`;

export const GoogleMapsToolTip = styled.li`
  list-style: none;
  object-fit: contain;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const ModalTitle = styled.div`
  width: 292px;
  height: 40px;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: #221666;
  padding-left: 20px;
`;

export const ServerErrorPara = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray};
  margin-right: 20px;
  font-stretch: normal;
  padding-left: 20px;
  margin-top: 80px;
`;

export const BlueSpan = styled.span`
  color: ${colors.pastelDarkBlue};
  font-family: "Open Sans";
`;

type ChevronDivProps = {
  isOpen?: boolean;
};

export const ChevronDiv = styled.span<ChevronDivProps>`
  display: inline-block;
  border: none;
  background-image: ${(props) =>
    props.isOpen ? `url(${chevronUp})` : `url(${chevronDown})`};
  background-repeat: no-repeat, no-repeat;
  background-position: right center;
  padding-right: 15px;
  background-size: 10px 10px, 20px 20px;
`;
