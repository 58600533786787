import { gusTheme } from "@components/Theme";
import React from "react";
import iconPath from "./iconsLib";
export interface IIConProps {
  size: number;
  color: string;
  icon: string;
  viewBox: string;
  style: Object;
  className?: string;
  testId?: string;
}
const defaultStyles = { display: "inline-block", verticalAlign: "middle" };

const Icon = ({
  size = 20,
  color = "primary1",
  icon,
  className,
  style,
  viewBox = "0 0 24 24",
  testId,
}: IIConProps) => {
  const styles = { ...defaultStyles, ...style };

  return (
    <svg
      className={className}
      style={styles}
      viewBox={viewBox}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid={testId ? testId : null}
    >
      {Array.isArray(iconPath[icon].path) ? (
        iconPath[icon].path.map((d, i) => {
          return (
            <path
              key={d}
              fill={gusTheme["colors"][color]}
              d={d}
              fillRule={iconPath[icon].fillRule}
              clipRule={iconPath[icon].clipRule}
              stroke={iconPath[icon].stroke && gusTheme["colors"][color]}
            />
          );
        })
      ) : (
        <path
          fill={gusTheme["colors"][color]}
          d={iconPath[icon].path}
          fillRule={iconPath[icon].fillRule}
          clipRule={iconPath[icon].clipRule}
          stroke={iconPath[icon].stroke && gusTheme["colors"][color]}
        />
      )}
    </svg>
  );
};

Icon.defaultProps = {
  size: 20,
  color: "primary1",
  viewBox: "0 0 24 24",
  style: {},
  className: "",
};

export default Icon;
