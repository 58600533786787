import * as React from "react";
import { FunctionComponent } from "react";

import Modal from "react-modal";
import FadeIn from "react-fade-in";

import { ServerErrors } from "@styles/components/elements/Headers";
import { ButtonGrouping } from "@styles/components/elements/Buttons";
import { BackLinkModal } from "@styles/components/elements/Links";
import { ServerErrorPara } from "@styles/components/elements/Paragraphs";
import { ErrorModalStyles } from "@styles/components/base/modals/modals";

interface MyProps {
  isOpen: boolean;
  onAfterOpen: any;
  errorText: any;
  onRequestClose: any;
}

const ServerErrorModal: FunctionComponent<MyProps> = (props) => {
  return (
    <FadeIn delay={125}>
      <Modal
        isOpen={props.isOpen}
        onAfterOpen={props.onAfterOpen}
        style={ErrorModalStyles}
        ariaHideApp={!props.isOpen}
      >
        <ServerErrors>{props.errorText}</ServerErrors>
        <ServerErrorPara>Please fix errors and try again.</ServerErrorPara>
        <ButtonGrouping>
          <BackLinkModal onClick={props.onRequestClose}>Cancel</BackLinkModal>
        </ButtonGrouping>
      </Modal>
    </FadeIn>
  );
};

export default ServerErrorModal;
