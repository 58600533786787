import hexRgb from "hex-rgb";
import { colors } from "@styles/colors";

export const overlayBackground = hexRgb(colors.baseDarkest, {
  format: "css",
  alpha: 0.7,
});

export const standardModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "491px",
    height: "491px",
    overflow: "hidden",
    padding: "0px",
    borderRadius: "20px",
    background: colors.baseWhite,
  },
  overlay: {
    background: overlayBackground,
    zIndex: 99,
  },
};

export const AlertModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "350px",
    minHeight: "220px",
    overflow: "hidden",
    padding: "20px",
    borderRadius: "20px",
    background: colors.baseWhite,
  },
  overlay: {
    background: overlayBackground,
    zIndex: 99,
  },
};

export const FVFModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "500px",
    height: "auto",
    overflow: "hidden",
    borderRadius: "13px",
    border: "none",
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingBottom: "25px",
    display: "inline",
  },
  overlay: {
    background: overlayBackground,
    zIndex: 99,
  },
};

export const ErrorModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "350px",
    overflow: "hidden",
    padding: "0px",
    borderRadius: "20px",
    background: colors.baseWhite,
  },
  overlay: {
    background: overlayBackground,
    zIndex: 99,
  },
};
