import styled, { css } from "styled-components";
import { colors } from "../../colors";
import AlertSVG from "@views/builder/sites/assets/Alert.svg";
interface BaseInputParams {
  errorMessage?: boolean;
  img?: string;
  width?: string;
}

export const BaseLabel = styled.label`
  width: 380px;
  padding-top: 16px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 32px;
  white-space: nowrap;
  color: ${colors.catalinaBlue};
  font-family: "Open Sans";
`;

export const OnboardingLabel = styled.label`
  width: 380px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 25px;
  white-space: nowrap;
  color: ${colors.catalinaBlue};
  font-family: "Open Sans";
`;

export const Shimmer = styled.div`
  padding-top: 16px;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 32px;
  white-space: nowrap;
  display: inline;
`;

export const Shimmered = styled.div`
  padding-top: 16px;
  padding-left: 5px;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 32px;
  text-align: left;
  color: rgba(230, 236, 241, 0.3);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: -moz-gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  background: gradient(
    linear,
    left top,
    right top,
    from(#222),
    to(#222),
    color-stop(0.5, #fff)
  );
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: Shimmer;
  -moz-animation-name: Shimmer;
  animation-name: Shimmer;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: ${colors.catalinaBlue};

  @-moz-keyframes Shimmer {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }
  @-webkit-keyframes Shimmer {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }
  @keyframes Shimmer {
    0% {
      background-position: top left;
    }
    100% {
      background-position: top right;
    }
  }
`;

export const FVFLabel = styled.label`
  font-family: Open Sans;
  line-height: 27px;
  margin-top: 32px;
  margin-bottom: 0px;
  width: 436px;
  height: 29px;
  object-fit: contain;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: ${colors.gray};
`;

export const BaseInput = styled.input<BaseInputParams>` 
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "100%!important")};
  height: 48px !important;
  background-color: ${colors.white};
  border: 2px solid
    ${(props) =>
      props.errorMessage ? colors.validationRed : colors.grayBorder};
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  color: ${colors.gray};
  background-image: ${(props) =>
    props.errorMessage ? `url(${AlertSVG})` : ""};
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  :focus {
    outline-color: ${(props) =>
      props.errorMessage ? colors.validationRed : colors.pastelDarkBlue};
    border: 2px solid
      ${(props) =>
        props.errorMessage ? colors.validationRed : colors.grayBorder};
    background-color: ${colors.white};
    color: ${colors.gray};
  }
  :hover {
    border-color:  ${(props) =>
      props.errorMessage ? colors.validationRed : "#cccccc"};;
  }
  &::-webkit-input-placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    color: ${colors.gray};
  }
`;

export const OnboardingBaseInput = styled(BaseInput)`
  margin-left: 12px;
  width: 600px;
  padding-left: 10px;
`;

export const BaseTextArea = styled.textarea<BaseInputParams>`
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 48px !important;
  background-color: ${colors.white};
  border: 2px solid
    ${(props) =>
      props.errorMessage ? colors.validationRed : colors.grayBorder};
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  align-items: center;
  color: ${colors.gray};
  background-image: ${(props) =>
    props.errorMessage ? `url(${AlertSVG})` : ""};
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  :focus {
    outline-color: ${(props) =>
      props.errorMessage ? colors.validationRed : colors.pastelDarkBlue};
    border: 2px solid
      ${(props) =>
        props.errorMessage ? colors.validationRed : colors.grayBorder};
    background-color: ${colors.white};
    color: ${colors.gray};
  }
  :hover {
    border-color: #cccccc;
  }
  &::-webkit-input-placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    color: ${colors.gray};
  }
`;

export const Input1Styled = styled(BaseInput)`
  width: 435px !important;
`;

export const FVFInput = styled.input<BaseInputParams>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  background-image: ${(props) =>
    props.errorMessage ? `url(${AlertSVG})` : ""};
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  color: ${colors.gray};
  margin-top: 8px;
  width: 436px;
  height: 44px;
  border: 2px solid
    ${(props) =>
      props.errorMessage ? colors.validationRed : colors.grayBorder};
  box-sizing: border-box;
  border-radius: 4px;
  :focus {
    outline-color: ${(props) =>
      props.errorMessage ? colors.validationRed : colors.blueRey};
    border: 2px solid
      ${(props) =>
        props.errorMessage ? colors.validationRed : colors.grayBorder};
    background-color: ${colors.white};
    color: ${colors.gray};
  }
`;

export const BizListingTitle = styled.span`
  color: ${colors.catalinaBlue};
  padding-bottom: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

import checkboxUnchecked from "@views/builder/sites/assets/checkbox_unchecked.svg";
import checkboxHover from "@views/builder/sites/assets/checkbox_hover.svg";
import checkboxChecked from "@views/builder/sites/assets/checkbox_checked.svg";
import checkboxCheckedReports from "@views/builder/sites/assets/Checked.svg";
import disabledCheckbox from "../../../assets/icons/checkboxes/disabled-square.svg";
import grayCheckbox from "../../../assets/icons/checkboxes/square-darkgray.svg";
import grayCheckboxChecked from "../../../assets/icons/checkboxes/check-square-darkgray.svg";
interface CheckboxOverlay {
  checked?: boolean;
}

export const DisabledCheckbox = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(${disabledCheckbox});
  cursor: not-allowed;
`;
export const GrayCheckbox = styled.div<CheckboxOverlay>`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: ${(props) =>
      props.checked ? `url(${grayCheckboxChecked})` : `url(${grayCheckbox})`}
    no-repeat;
  &:hover {
    background: ${(props) =>
        props.checked ? `url(${grayCheckboxChecked})` : `url(${grayCheckbox})`}
      no-repeat;
  }
`;

export const StyledCheckbox = styled.div<CheckboxOverlay>`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: ${(props) =>
      props.checked ? `url(${checkboxChecked})` : `url(${checkboxUnchecked})`}
    no-repeat;
  &:hover {
    background: ${(props) =>
        props.checked ? `url(${checkboxChecked})` : `url(${checkboxHover})`}
      no-repeat;
  }
`;

export const StyledCheckboxReports = styled.div<CheckboxOverlay>`
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 3px;
  background: ${(props) =>
      props.checked
        ? `url(${checkboxCheckedReports})`
        : `url(${checkboxUnchecked})`}
    no-repeat;
  &:hover {
    background: ${(props) =>
        props.checked
          ? `url(${checkboxCheckedReports})`
          : `url(${checkboxUnchecked})`}
      no-repeat;
  }
`;
export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const BizListingSpan = styled.span`
  color: ${colors.gray};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;

export const BizListingLabel = styled.label`
  font-size: 15px;
  display: inline;
  padding-left: 16px;
`;

interface RegularInput {
  errorMessage?: boolean;
}

export const InputText = styled.input<RegularInput>`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  outline: none;
  line-height: 24px;
  height: 40px !important;
  width: 100%;
  box-sizing: border-box;
  color: ${colors.gray};
  background-color: ${colors.white};
  border: 2px solid
    ${(props) =>
      props.errorMessage ? colors.validationRed : colors.grayBorder};
  border-radius: 4px;

  :focus {
    outline-color: ${(props) =>
      props.errorMessage ? colors.validationRed : colors.blueRey};
    border: 2px solid
      ${(props) =>
        props.errorMessage ? colors.validationRed : colors.grayBorder};
    background-color: ${colors.white};
    color: ${colors.gray};
  }
  :hover {
    border-color: #cccccc;
  }
  &::-webkit-input-placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    color: ${colors.gray};
  }
`;

export const Label = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.catalinaBlue};
`;
